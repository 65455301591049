<template>
  <div class="empty-page">
    <div class="container overflow-hidden text-center">
      <div>
        <img src="../../public/assets/images/404.png" alt class="img-fluid" />
      </div>
      <div>
        <a href="javascript:;" class="robo-24-500" @click="goToHome">Về trang chủ</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page404',
  methods: {
    goToHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
.empty-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
