<template>
  <div class="call-container p-3">
    <!-- header`` -->

    <div
      v-if="currDisease && room"
      class="
        d-flex
        justify-content-between
        mb-2
        align-items-center
        bg-white
        p-1
        radius-10
      "
    >
      <div class="d-flex align-items-center">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 d-none d-md-block"
        >
          <path
            d="M12 16.2H19"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 20.2H16.38"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 10H18C20 10 20 9 20 8C20 6 19 6 18 6H14C13 6 12 6 12 8C12 10 13 10 14 10Z"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20 8.02002C23.33 8.20002 25 9.43002 25 14V20C25 24 24 26 19 26H13C8 26 7 24 7 20V14C7 9.44002 8.67 8.20002 12 8.02002"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="disease-in-process-name">
          <p class="mb-0">
            <span class="robo-18-500 mb-0 mr-4">
              Đang hội chẩn:
              {{ mdtUtils.getMdtPersonInfoString(currDisease) }}
              {{
                currDisease.patient_diagnostic &&
                " - " + currDisease.patient_diagnostic
              }}
            </span>
            <span class="robo-18-400 mb-0">
              {{ currDisease.doctor && currDisease.doctor.name }}
            </span>
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <button
          v-if="currDisease.doctor_id === doctor_id"
          class="btn bg-white bd-pri txt-pri btn-header mr-2"
          @click="showTTTQM(true)"
        >
          TTTQ Mổ
        </button>
        <button
          v-if="currDisease.doctor_id === doctor_id"
          class="btn bg-white bd-pri txt-pri btn-header mr-2"
          @click="showBienBan(true)"
        >
          Biên bản
        </button>
        <button
          v-if="currDisease.doctor_id === doctor_id"
          class="btn bg-pri bd-pri text-white bd-pri btn-header"
          @click="showDiseaseDetail(currDisease.id)"
        >
          Chi tiết ca bệnh
        </button>
      </div>
    </div>
    <div style="height: 45px" v-if="!currDisease"></div>
    <!-- header`` -->
    <!-- body -->
    <div class="row stream-wraper" v-if="room">
      <div class="" :class="isVertical ? 'col-12' : 'col-9'">
        <div id="zoomed-wraper" :class="isVertical ? 'vertical' : 'horizontal'">
          <Participant
            :participant="mainParticipantsTrack"
            v-if="mainParticipantsTrack"
            :tracks="mainParticipantsTrack.tracks"
            :membersMdtRoom="room_members"
          />
        </div>
      </div>
      <div
        class=""
        id="media-container"
        :class="isVertical ? 'vertical col-12' : 'horizontal col-3'"
      >
        <div
          v-for="(participant, i) in participantsTracks"
          :key="participant.id + 'mini-participant' + i"
        >
          <Participant
            @zoomTrack="zoomPTrack"
            :participant="participant"
            :tracks="participant.tracks"
            :membersMdtRoom="room_members"
            :key="participant.id + 'mini-participant' + i + 'p-cpn'"
          />
        </div>
      </div>
    </div>
    <div class="empty-wraper" v-if="!room">
      <img
        src="../../../public/assets/images/gif/Loading.gif"
        width="212"
        height="212"
        alt=""
      />
    </div>
    <!-- body -->
    <!-- footer -->
    <div
      v-if="room"
      class="d-flex footer-wraper align-items-center"
      :set="(canShare = canScreenShare())"
    >
      <div class="room-name">
        <p class="robo-24-500 mb-0 text-white">
          {{ room_info && room_info.name }}
        </p>
      </div>
      <div class="bottom-action">
        <!-- camera -->
        <button
          @click.prevent="camChangeStatus()"
          class="b-action"
          :class="camStatus && hasVideo ? 'action-active' : 'action-inactive'"
          :disabled="!hasVideo || !room || changing_cam"
        >
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="camStatus && hasVideo"
          >
            <path
              d="M28.1999 8.22659C27.6533 7.93325 26.5066 7.62659 24.9466 8.71992L22.9866 10.1066C22.8399 5.95992 21.0399 4.33325 16.6666 4.33325H8.66659C4.10659 4.33325 2.33325 6.10659 2.33325 10.6666V21.3333C2.33325 24.3999 3.99992 27.6666 8.66659 27.6666H16.6666C21.0399 27.6666 22.8399 26.0399 22.9866 21.8933L24.9466 23.2799C25.7733 23.8666 26.4933 24.0533 27.0666 24.0533C27.5599 24.0533 27.9466 23.9066 28.1999 23.7733C28.7466 23.4932 29.6666 22.7333 29.6666 20.8266V11.1733C29.6666 9.26659 28.7466 8.50659 28.1999 8.22659ZM14.6666 15.1733C13.2933 15.1733 12.1599 14.0533 12.1599 12.6666C12.1599 11.2799 13.2933 10.1599 14.6666 10.1599C16.0399 10.1599 17.1733 11.2799 17.1733 12.6666C17.1733 14.0533 16.0399 15.1733 14.6666 15.1733Z"
              fill="white"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!camStatus || !hasVideo"
          >
            <path
              opacity="0.4"
              d="M23.6532 10.0933C23.6666 10.1866 23.6666 10.2933 23.6532 10.3866C23.6532 10.2933 23.6399 10.1999 23.6399 10.1066L23.6532 10.0933Z"
              fill="#757577"
            />
            <path
              d="M23.0399 8.74658L5.10659 26.6799C3.23993 25.4933 2.50659 23.3733 2.50659 21.3333V10.6666C2.50659 6.10659 4.27993 4.33325 8.83993 4.33325H16.8399C20.6933 4.33325 22.5466 5.59992 23.0399 8.74658Z"
              fill="#757577"
            />
            <path
              d="M28.5334 2.97334C28.1334 2.57334 27.4801 2.57334 27.0801 2.97334L2.46675 27.5867C2.06675 27.9867 2.06675 28.64 2.46675 29.04C2.66675 29.2267 2.93341 29.3333 3.18675 29.3333C3.45341 29.3333 3.70675 29.2267 3.90675 29.0267L28.5334 4.41334C28.9334 4.01334 28.9334 3.37334 28.5334 2.97334Z"
              fill="#757577"
            />
            <path
              d="M29.8399 11.1734V20.8267C29.8399 22.7334 28.9066 23.4934 28.3732 23.7734C28.1199 23.9067 27.7199 24.0534 27.2266 24.0534C26.6532 24.0534 25.9466 23.8667 25.1199 23.2801L23.1466 21.8934C23.0532 24.8401 22.1199 26.5201 19.9999 27.2267C19.1466 27.5334 18.0932 27.6667 16.8265 27.6667H8.82655C8.50655 27.6667 8.19988 27.6534 7.87988 27.6134L19.9999 15.5067L27.5332 7.97339C27.8799 8.00006 28.1599 8.10672 28.3732 8.22672C28.9066 8.50672 29.8399 9.26672 29.8399 11.1734Z"
              fill="#757577"
            />
          </svg>
        </button>
        <!-- camera -->
        <!-- mic -->
        <button
          @click.prevent="micChangeStatus()"
          class="b-action"
          :class="micStatus && hasAudio ? 'action-active' : 'action-inactive'"
          :disabled="!hasAudio || !room || changing_mic"
        >
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="micStatus && hasAudio"
          >
            <path
              d="M25.4932 12.1598C24.9732 12.1598 24.5599 12.5732 24.5599 13.0932V15.1998C24.5599 19.9198 20.7199 23.7598 15.9999 23.7598C11.2799 23.7598 7.43991 19.9198 7.43991 15.1998V13.0798C7.43991 12.5598 7.02658 12.1465 6.50658 12.1465C5.98658 12.1465 5.57324 12.5598 5.57324 13.0798V15.1865C5.57324 20.6132 9.74658 25.0798 15.0666 25.5598V28.3998C15.0666 28.9198 15.4799 29.3332 15.9999 29.3332C16.5199 29.3332 16.9332 28.9198 16.9332 28.3998V25.5598C22.2399 25.0932 26.4266 20.6132 26.4266 15.1865V13.0798C26.4132 12.5732 25.9999 12.1598 25.4932 12.1598Z"
              fill="white"
            />
            <path
              d="M16 2.66675C12.7467 2.66675 10.1067 5.30675 10.1067 8.56008V15.3867C10.1067 18.6401 12.7467 21.2801 16 21.2801C19.2534 21.2801 21.8934 18.6401 21.8934 15.3867V8.56008C21.8934 5.30675 19.2534 2.66675 16 2.66675ZM17.7467 11.9334C17.6534 12.2801 17.3467 12.5067 17 12.5067C16.9334 12.5067 16.8667 12.4934 16.8 12.4801C16.28 12.3334 15.7334 12.3334 15.2134 12.4801C14.7867 12.6001 14.3734 12.3467 14.2667 11.9334C14.1467 11.5201 14.4 11.0934 14.8134 10.9867C15.6 10.7734 16.4267 10.7734 17.2134 10.9867C17.6134 11.0934 17.8534 11.5201 17.7467 11.9334ZM18.4534 9.34675C18.3334 9.66675 18.04 9.85341 17.72 9.85341C17.6267 9.85341 17.5467 9.84008 17.4534 9.81341C16.52 9.46675 15.48 9.46675 14.5467 9.81341C14.1467 9.96008 13.6934 9.74675 13.5467 9.34675C13.4 8.94675 13.6134 8.49341 14.0134 8.36008C15.2934 7.89341 16.7067 7.89341 17.9867 8.36008C18.3867 8.50675 18.6 8.94675 18.4534 9.34675Z"
              fill="white"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!micStatus || !hasAudio"
          >
            <path
              d="M21.8934 8.56002V10.1067L12.1867 19.8134C10.9067 18.6534 10.1067 16.9467 10.1067 15.12V8.56002C10.1067 5.81335 11.9734 3.53335 14.5067 2.88002C14.76 2.81335 15 3.02669 15 3.28002V5.33335C15 5.88002 15.4534 6.33335 16 6.33335C16.5467 6.33335 17 5.88002 17 5.33335V3.28002C17 3.02669 17.24 2.81335 17.4934 2.88002C20.0267 3.53335 21.8934 5.81335 21.8934 8.56002Z"
              fill="#757577"
            />
            <path
              d="M26.4134 13.0799V15.1999C26.4134 20.6266 22.2401 25.0932 16.9334 25.5599V28.3999C16.9334 28.9199 16.5201 29.3332 16.0001 29.3332C15.4801 29.3332 15.0668 28.9199 15.0668 28.3999V25.5599C13.6134 25.4266 12.2401 24.9999 11.0134 24.3199L12.3868 22.9466C13.4801 23.4532 14.7068 23.7466 16.0001 23.7466C20.7201 23.7466 24.5601 19.9066 24.5601 15.1999V13.0799C24.5601 12.5732 24.9734 12.1599 25.4934 12.1599C26.0001 12.1599 26.4134 12.5732 26.4134 13.0799Z"
              fill="#757577"
            />
            <path
              d="M21.8934 13.4399V15.3733C21.8934 18.8133 18.9334 21.5733 15.4134 21.2399C15.0401 21.1999 14.6667 21.1333 14.3201 21.0133L21.8934 13.4399Z"
              fill="#757577"
            />
            <path
              d="M29.0266 2.97334C28.6266 2.57334 27.9732 2.57334 27.5732 2.97334L9.63991 20.9067C8.26658 19.4 7.43991 17.4 7.43991 15.2V13.08C7.43991 12.5733 7.02658 12.16 6.50658 12.16C5.99991 12.16 5.58658 12.5733 5.58658 13.08V15.2C5.58658 17.9067 6.62658 20.3733 8.31991 22.2267L2.95991 27.5867C2.55991 27.9867 2.55991 28.64 2.95991 29.04C3.17325 29.2267 3.42658 29.3333 3.69325 29.3333C3.95991 29.3333 4.21325 29.2267 4.41325 29.0267L29.0266 4.41334C29.4399 4.01334 29.4399 3.37334 29.0266 2.97334Z"
              fill="#757577"
            />
          </svg>
        </button>
        <!-- mic -->

        <button
          v-if="canShare"
          @click.prevent="shareScreenHandler()"
          class="b-action"
          :class="isShareScreen ? 'action-active' : 'action-inactive'"
          :disabled="!canShare"
        >
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="isShareScreen"
          >
            <rect
              x="1"
              y="4"
              width="30"
              height="24"
              rx="3"
              stroke="white"
              stroke-width="1.5"
            />
            <path
              d="M16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L10.6967 14.2426C10.4038 14.5355 10.4038 15.0104 10.6967 15.3033C10.9896 15.5962 11.4645 15.5962 11.7574 15.3033L16 11.0607L20.2426 15.3033C20.5355 15.5962 21.0104 15.5962 21.3033 15.3033C21.5962 15.0104 21.5962 14.5355 21.3033 14.2426L16.5303 9.46967ZM16.75 23L16.75 10L15.25 10L15.25 23L16.75 23Z"
              fill="white"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!isShareScreen || !canShare"
          >
            <rect
              x="1"
              y="4"
              width="30"
              height="24"
              rx="3"
              stroke="#757577"
              stroke-width="1.5"
            />
            <path
              d="M16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L10.6967 14.2426C10.4038 14.5355 10.4038 15.0104 10.6967 15.3033C10.9896 15.5962 11.4645 15.5962 11.7574 15.3033L16 11.0607L20.2426 15.3033C20.5355 15.5962 21.0104 15.5962 21.3033 15.3033C21.5962 15.0104 21.5962 14.5355 21.3033 14.2426L16.5303 9.46967ZM16.75 23L16.75 10L15.25 10L15.25 23L16.75 23Z"
              fill="#757577"
            />
          </svg>
        </button>

        <button @click="onDisconect()" class="b-action b-leave cursor-pointer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.73686 15.178C5.52323 14.3917 6.41069 13.7569 7.35179 13.2832C7.63919 13.1347 7.83816 12.8347 7.83813 12.4873L7.83496 9.705C10.5352 8.82387 13.4596 8.82387 16.1567 9.70183L16.163 12.4873C16.163 12.8347 16.362 13.1347 16.6493 13.2832C17.5904 13.7569 18.4779 14.3917 19.2643 15.178C19.6149 15.5286 20.177 15.5286 20.5276 15.178L22.7383 12.9673C23.0857 12.6199 23.0888 12.0546 22.7383 11.7041C16.8073 5.77311 7.1939 5.77311 1.26291 11.7041C0.912362 12.0546 0.912362 12.6168 1.26291 12.9673L3.47362 15.178C3.82417 15.5286 4.3863 15.5286 4.73686 15.178Z"
              fill="#FBFAFF"
            />
          </svg>
        </button>
      </div>
      <div class="info-btn-group">
        <div class="btn-open-tab bg-3C4043 d-none d-sm-flex">
          <p class="robo-18-400 mb-0 text-white">
            {{ fancyTimeFormat(total_time) }}
          </p>
        </div>
        <div class="btn-open-tab bg-3C4043 d-none d-sm-flex">
          <p class="robo-18-400 mb-0 text-white mr-2">
            {{ getMemberJoined() }}
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
              fill="white"
            />
            <path
              d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
              fill="white"
            />
            <path
              d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z"
              fill="white"
            />
            <path
              d="M21.9902 16.5899C21.9102 17.5599 21.2902 18.3999 20.2502 18.9699C19.2502 19.5199 17.9902 19.7799 16.7402 19.7499C17.4602 19.0999 17.8802 18.2899 17.9602 17.4299C18.0602 16.1899 17.4702 14.9999 16.2902 14.0499C15.6202 13.5199 14.8402 13.0999 13.9902 12.7899C16.2002 12.1499 18.9802 12.5799 20.6902 13.9599C21.6102 14.6999 22.0802 15.6299 21.9902 16.5899Z"
              fill="white"
            />
          </svg>
        </div>
        <!-- nút mở hồ sơ & thông tin phòng -->
        <div
          class="btn-open-tab cursor-pointer d-none d-sm-flex"
          @click="showInfo()"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!show_info"
          >
            <path
              d="M16.0003 29.3332C23.3337 29.3332 29.3337 23.3332 29.3337 15.9998C29.3337 8.6665 23.3337 2.6665 16.0003 2.6665C8.66699 2.6665 2.66699 8.6665 2.66699 15.9998C2.66699 23.3332 8.66699 29.3332 16.0003 29.3332Z"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 10.6665V17.3332"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9922 21.3335H16.0042"
              stroke="#fff"
              stroke-width="2.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="show_info"
          >
            <path
              d="M15.9998 2.6665C8.65317 2.6665 2.6665 8.65317 2.6665 15.9998C2.6665 23.3465 8.65317 29.3332 15.9998 29.3332C23.3465 29.3332 29.3332 23.3465 29.3332 15.9998C29.3332 8.65317 23.3465 2.6665 15.9998 2.6665ZM14.9998 10.6665C14.9998 10.1198 15.4532 9.6665 15.9998 9.6665C16.5465 9.6665 16.9998 10.1198 16.9998 10.6665V17.3332C16.9998 17.8798 16.5465 18.3332 15.9998 18.3332C15.4532 18.3332 14.9998 17.8798 14.9998 17.3332V10.6665ZM17.2265 21.8398C17.1598 22.0132 17.0665 22.1465 16.9465 22.2798C16.8132 22.3998 16.6665 22.4932 16.5065 22.5598C16.3465 22.6265 16.1732 22.6665 15.9998 22.6665C15.8265 22.6665 15.6532 22.6265 15.4932 22.5598C15.3332 22.4932 15.1865 22.3998 15.0532 22.2798C14.9332 22.1465 14.8398 22.0132 14.7732 21.8398C14.7065 21.6798 14.6665 21.5065 14.6665 21.3332C14.6665 21.1598 14.7065 20.9865 14.7732 20.8265C14.8398 20.6665 14.9332 20.5198 15.0532 20.3865C15.1865 20.2665 15.3332 20.1732 15.4932 20.1065C15.8132 19.9732 16.1865 19.9732 16.5065 20.1065C16.6665 20.1732 16.8132 20.2665 16.9465 20.3865C17.0665 20.5198 17.1598 20.6665 17.2265 20.8265C17.2932 20.9865 17.3332 21.1598 17.3332 21.3332C17.3332 21.5065 17.2932 21.6798 17.2265 21.8398Z"
              fill="#fff"
            />
          </svg>
        </div>
        <div
          class="btn-open-tab cursor-pointer d-none d-sm-flex"
          @click="showDisease()"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!show_disease"
          >
            <path
              d="M10.667 16.2666H20.0003"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.667 21.6001H16.507"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.3337 7.99984H18.667C21.3337 7.99984 21.3337 6.6665 21.3337 5.33317C21.3337 2.6665 20.0003 2.6665 18.667 2.6665H13.3337C12.0003 2.6665 10.667 2.6665 10.667 5.33317C10.667 7.99984 12.0003 7.99984 13.3337 7.99984Z"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3333 5.35986C25.7733 5.59986 28 7.23986 28 13.3332V21.3332C28 26.6665 26.6667 29.3332 20 29.3332H12C5.33333 29.3332 4 26.6665 4 21.3332V13.3332C4 7.2532 6.22667 5.59986 10.6667 5.35986"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="show_disease"
          >
            <path
              d="M19.1333 2.6665H12.8667C11.48 2.6665 10.3467 3.7865 10.3467 5.17317V6.4265C10.3467 7.81317 11.4667 8.93317 12.8533 8.93317H19.1333C20.52 8.93317 21.64 7.81317 21.64 6.4265V5.17317C21.6533 3.7865 20.52 2.6665 19.1333 2.6665Z"
              fill="#fff"
            />
            <path
              d="M22.9867 6.42664C22.9867 8.54664 21.2534 10.28 19.1334 10.28H12.8667C10.7467 10.28 9.01339 8.54664 9.01339 6.42664C9.01339 5.67997 8.21339 5.21331 7.54672 5.55997C5.66672 6.55997 4.38672 8.54664 4.38672 10.8266V23.3733C4.38672 26.6533 7.06672 29.3333 10.3467 29.3333H21.6534C24.9334 29.3333 27.6134 26.6533 27.6134 23.3733V10.8266C27.6134 8.54664 26.3334 6.55997 24.4534 5.55997C23.7867 5.21331 22.9867 5.67997 22.9867 6.42664ZM16.5067 22.6H10.6667C10.1201 22.6 9.66672 22.1466 9.66672 21.6C9.66672 21.0533 10.1201 20.6 10.6667 20.6H16.5067C17.0534 20.6 17.5067 21.0533 17.5067 21.6C17.5067 22.1466 17.0534 22.6 16.5067 22.6ZM20.0001 17.2666H10.6667C10.1201 17.2666 9.66672 16.8133 9.66672 16.2666C9.66672 15.72 10.1201 15.2666 10.6667 15.2666H20.0001C20.5467 15.2666 21.0001 15.72 21.0001 16.2666C21.0001 16.8133 20.5467 17.2666 20.0001 17.2666Z"
              fill="#fff"
            />
          </svg>
        </div>
        <!-- !nút mở hồ sơ & thông tin phòng -->
        <!-- info btn mobile -->
        <div class="dropdown d-sm-none">
          <a
            class="mx-1"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="closeInfoNDisease"
          >
            <svg
              width="31"
              height="32"
              viewBox="0 0 31 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5226 21.4949C25.1452 21.8799 24.5546 21.9149 24.1384 21.5999L24.0192 21.4949L15.5007 12.8052L6.98214 21.4949C6.60472 21.8799 6.01411 21.9149 5.59793 21.5999L5.47869 21.4949C5.10127 21.1099 5.06696 20.5074 5.37576 20.0829L5.47869 19.9613L14.7489 10.5051C15.1264 10.1201 15.717 10.0851 16.1331 10.4001L16.2524 10.5051L25.5226 19.9613C25.9378 20.3848 25.9378 21.0714 25.5226 21.4949Z"
                fill="white"
              />
            </svg>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              class="
                dropdown-item
                d-flex
                align-items-center
                justify-content-between
              "
              href="javascript:;"
            >
              <div class="d-flex align-items-center">
                <p class="robo-18-400 mb-0 txt-black">
                  {{ fancyTimeFormat(total_time) }}
                </p>
              </div>
              <div class="d-flex align-items-center">
                <p class="robo-18-400 mb-0 txt-black">
                  {{ getMemberJoined() }}
                </p>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                    fill="#313131"
                  />
                  <path
                    d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
                    fill="#313131"
                  />
                  <path
                    d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z"
                    fill="#313131"
                  />
                  <path
                    d="M21.9902 16.5899C21.9102 17.5599 21.2902 18.3999 20.2502 18.9699C19.2502 19.5199 17.9902 19.7799 16.7402 19.7499C17.4602 19.0999 17.8802 18.2899 17.9602 17.4299C18.0602 16.1899 17.4702 14.9999 16.2902 14.0499C15.6202 13.5199 14.8402 13.0999 13.9902 12.7899C16.2002 12.1499 18.9802 12.5799 20.6902 13.9599C21.6102 14.6999 22.0802 15.6299 21.9902 16.5899Z"
                    fill="#313131"
                  />
                </svg>
              </div>
            </a>
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              @click="showInfo()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!show_info"
                class="mr-2"
              >
                <path
                  d="M16.0003 29.3332C23.3337 29.3332 29.3337 23.3332 29.3337 15.9998C29.3337 8.6665 23.3337 2.6665 16.0003 2.6665C8.66699 2.6665 2.66699 8.6665 2.66699 15.9998C2.66699 23.3332 8.66699 29.3332 16.0003 29.3332Z"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 10.6665V17.3332"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.9922 21.3335H16.0042"
                  stroke="#313131"
                  stroke-width="2.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="show_info"
                class="mr-2"
              >
                <path
                  d="M15.9998 2.6665C8.65317 2.6665 2.6665 8.65317 2.6665 15.9998C2.6665 23.3465 8.65317 29.3332 15.9998 29.3332C23.3465 29.3332 29.3332 23.3465 29.3332 15.9998C29.3332 8.65317 23.3465 2.6665 15.9998 2.6665ZM14.9998 10.6665C14.9998 10.1198 15.4532 9.6665 15.9998 9.6665C16.5465 9.6665 16.9998 10.1198 16.9998 10.6665V17.3332C16.9998 17.8798 16.5465 18.3332 15.9998 18.3332C15.4532 18.3332 14.9998 17.8798 14.9998 17.3332V10.6665ZM17.2265 21.8398C17.1598 22.0132 17.0665 22.1465 16.9465 22.2798C16.8132 22.3998 16.6665 22.4932 16.5065 22.5598C16.3465 22.6265 16.1732 22.6665 15.9998 22.6665C15.8265 22.6665 15.6532 22.6265 15.4932 22.5598C15.3332 22.4932 15.1865 22.3998 15.0532 22.2798C14.9332 22.1465 14.8398 22.0132 14.7732 21.8398C14.7065 21.6798 14.6665 21.5065 14.6665 21.3332C14.6665 21.1598 14.7065 20.9865 14.7732 20.8265C14.8398 20.6665 14.9332 20.5198 15.0532 20.3865C15.1865 20.2665 15.3332 20.1732 15.4932 20.1065C15.8132 19.9732 16.1865 19.9732 16.5065 20.1065C16.6665 20.1732 16.8132 20.2665 16.9465 20.3865C17.0665 20.5198 17.1598 20.6665 17.2265 20.8265C17.2932 20.9865 17.3332 21.1598 17.3332 21.3332C17.3332 21.5065 17.2932 21.6798 17.2265 21.8398Z"
                  fill="#313131"
                />
              </svg>
              <p class="robo-18-400 mb-0 txt-black">Thông tin phòng</p>
            </a>
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              @click="showDisease()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!show_disease"
                class="mr-2"
              >
                <path
                  d="M10.667 16.2666H20.0003"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.667 21.6001H16.507"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.3337 7.99984H18.667C21.3337 7.99984 21.3337 6.6665 21.3337 5.33317C21.3337 2.6665 20.0003 2.6665 18.667 2.6665H13.3337C12.0003 2.6665 10.667 2.6665 10.667 5.33317C10.667 7.99984 12.0003 7.99984 13.3337 7.99984Z"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.3333 5.35986C25.7733 5.59986 28 7.23986 28 13.3332V21.3332C28 26.6665 26.6667 29.3332 20 29.3332H12C5.33333 29.3332 4 26.6665 4 21.3332V13.3332C4 7.2532 6.22667 5.59986 10.6667 5.35986"
                  stroke="#313131"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="show_disease"
                class="mr-2"
              >
                <path
                  d="M19.1333 2.6665H12.8667C11.48 2.6665 10.3467 3.7865 10.3467 5.17317V6.4265C10.3467 7.81317 11.4667 8.93317 12.8533 8.93317H19.1333C20.52 8.93317 21.64 7.81317 21.64 6.4265V5.17317C21.6533 3.7865 20.52 2.6665 19.1333 2.6665Z"
                  fill="#313131"
                />
                <path
                  d="M22.9867 6.42664C22.9867 8.54664 21.2534 10.28 19.1334 10.28H12.8667C10.7467 10.28 9.01339 8.54664 9.01339 6.42664C9.01339 5.67997 8.21339 5.21331 7.54672 5.55997C5.66672 6.55997 4.38672 8.54664 4.38672 10.8266V23.3733C4.38672 26.6533 7.06672 29.3333 10.3467 29.3333H21.6534C24.9334 29.3333 27.6134 26.6533 27.6134 23.3733V10.8266C27.6134 8.54664 26.3334 6.55997 24.4534 5.55997C23.7867 5.21331 22.9867 5.67997 22.9867 6.42664ZM16.5067 22.6H10.6667C10.1201 22.6 9.66672 22.1466 9.66672 21.6C9.66672 21.0533 10.1201 20.6 10.6667 20.6H16.5067C17.0534 20.6 17.5067 21.0533 17.5067 21.6C17.5067 22.1466 17.0534 22.6 16.5067 22.6ZM20.0001 17.2666H10.6667C10.1201 17.2666 9.66672 16.8133 9.66672 16.2666C9.66672 15.72 10.1201 15.2666 10.6667 15.2666H20.0001C20.5467 15.2666 21.0001 15.72 21.0001 16.2666C21.0001 16.8133 20.5467 17.2666 20.0001 17.2666Z"
                  fill="#313131"
                />
              </svg>
              <p class="robo-18-400 mb-0 txt-black">Danh sách ca bệnh</p>
            </a>
          </div>
        </div>
        <!-- !info btn mobile -->
      </div>
    </div>
    <!-- footer -->
    <div id="audio-wraper" class="d-none">
      <audio ref="audioJoin" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Join.mp3" type="audio/mp3" />
      </audio>
      <audio ref="audioLeave" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Out.mp3" type="audio/mp3" />
      </audio>
    </div>
  </div>
</template>
<script>
import ViewBienBan from '../../components/HoiChan/ViewBienBan.vue'
import Participant from '../../components/TwilioComponent/Participant.vue'
import LocalParticipantDiv from '../../components/TwilioComponent/LocalParticipant.vue'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
const {
  connect,
  createLocalVideoTrack,
  LocalVideoTrack,
  createLocalTracks,
  createLocalAudioTrack
} = require('twilio-video')
export default {
  name: 'TwilioVideoCall',
  props: ['room_name', 'room_info', 'room_members', 'checkin_members', 'mdt_diseases', 'currDisease', 'show_disease', 'show_info', 'started_seconds'],
  components: { ViewBienBan, Participant, LocalParticipantDiv },
  data () {
    return {
      token: '',
      connect,
      room: null,
      createLocalVideoTrack,
      is_support_browser: false,
      has_devices: [],
      screenTrack: null,
      isShareScreen: false,
      LocalVideoTrack,
      hasAudio: false,
      hasVideo: false,
      micStatus: false,
      camStatus: false,
      member_joined: [],
      member_joined_count: 0,
      mdtUtils,
      doctor_id: null,
      showing_disease: null,
      participantsTracks: [],
      mainParticipantsTrack: null,
      total_time: 0,
      start_count: null,
      changing_mic: false,
      changing_cam: false
    }
  },
  computed: {
    isVertical () {
      let w = window.screen.width
      let h = window.screen.height
      console.log(w / h)
      return (w / h) < (3 / 4)
    }
  },
  watch: {
    room (r) {
      if (!r) {
        this.member_joined_count = 0
      } else {
        this.member_joined_count = r.participants.size + 1 || 1
      }
    },
    started_seconds (s) {
      this.startTimeCount()
    }
  },
  created () {
    let self = this
    window.addEventListener('unload', function (e) {
      self.disconnect(e)
    })
  },
  mounted () {
    let self = this
    if (!self.room_name) return
    this.doctor_id = appUtils.getLocalUser().doctor.id
    self.has_devices = []
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      alert(
        'HODO không hỗ trợ dịch vụ này trên trình duyệt hiện tại, vui lòng sử dụng một trong các trình duyệt: Chrome/Microsoft Edge/Firefox'
      )
      self.is_support_browser = false
      self.backToList()
    } else {
      self.is_support_browser = true
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            self.has_devices.push(device.kind)
          })
        })
        .then(() => {
          if (
            navigator.userAgent.search('Safari') >= 0 &&
            navigator.userAgent.search('Chrome') < 0
          ) {
          } else {
            self.getToken()
          }
          self.hasVideo = !!self.has_devices.includes('videoinput')
          self.hasAudio = !!self.has_devices.includes('audioinput')
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message)
        })
    }
  },
  methods: {
    getMemberJoined () {
      return (this.room?.participants?.size + 1) || 1
    },
    async getToken () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getTwilioToken()
        .then((r) => {
          if (r && r.data && r.data.token) self.token = r.data.token
        })
        .then(() => {
          self.connectRoom()
        })
    },
    connectRoom () {
      let self = this
      let options = {
        audio: true,
        video: false
        // logLevel: 'debug'
      }
      if (self.hasVideo) {
        options.video = { width: 720 }
        self.camStatus = true
      }
      if (self.hasAudio) {
        options.audio = true
        self.micStatus = true
      }

      createLocalTracks(options).then(localTracks => {
        return connect(self.token, {
          name: self.room_name,
          tracks: localTracks
        })
      }).then(room => {
        self.room = room

        let pt = {
          id: room.localParticipant.sid,
          participant: room.localParticipant,
          tracks: self.getTrackDiffScreens(room.localParticipant),
          type: 'normal'
        }
        console.log(pt)
        this.mainParticipantsTrack = pt

        console.log(`Successfully joined a Room: ${self.room}`)
        // Log new Participants as they connect to the Room
        self.room.once('participantConnected', (participant) => {
          console.log(
            `Participant "${participant.identity}" has connected to the Room`
          )
        })

        // Log Participants as they disconnect from the Room
        self.room.once('participantDisconnected', (participant) => {
          console.log(
            `Participant "${participant.identity}" has disconnected from the Room`
          )
        })

        // Remote tham gia phòng
        self.room.on('participantConnected', (participant) => {
          console.log(`Participant connected: ${participant.identity}`)
          try {
            self.$refs.audioJoin.play()
          } catch (e) {
            console.log(e)
          }
          self.createParticipantsTracks(participant)
          // thêm video/track các remote tham gia
          participant.on('trackSubscribed', (track) => {
            self.trackSubscribed(track, participant)
          })
          // remove track
          participant.on('trackUnsubscribed', (track) => {
            self.trackUnsubscribed(track, participant)
          })
          participant.on('trackDisabled', (track) => {
            console.log(track)
          })
        })

        self.room.on('participantDisconnected', (participant) => {
          console.log(`Participant disconnected: ${participant.identity}`)
          // self.getTracks(participant).forEach(function (track) {
          //   track.detach().forEach(function (element) {
          //     element.srcObject = null
          //     element.remove()
          //   })
          // })
          try {
            self.$refs.audioLeave.play()
          } catch (e) {
            console.log(e)
          }
          self.removeParticipant(participant)
        })

        // Thêm các video/track remote đã có trong phòng
        self.room.participants.forEach((participant) => {
          console.log(`Participant connected: ${participant.identity}`)
          // add khi có thêm track mới

          self.createParticipantsTracks(participant)
          // thêm video/track các remote tham gia
          participant.on('trackSubscribed', (track) => {
            self.trackSubscribed(track, participant)
          })
          // remove track
          participant.on('trackUnsubscribed', (track) => {
            self.trackUnsubscribed(track, participant)
          })
          participant.on('trackDisabled', (track) => {
            console.log(track)
          })
        })
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`)
        self.$toast.open({
          message: 'Tham gia thất bại vui lòng thử lại sau',
          type: 'warning'
        })
        self.backToList()
      }
      )
    },
    disconnect () {
      let self = this
      this.stopTimeCount()
      if (!this.room) return
      this.room.on('disconnected', (room) => {
        // Detach the local media elements
        this.room.localParticipant.tracks.forEach((publication) => {
          const attachedElements = publication.track.detach()
          publication.unpublish()
          publication.track.stop()
          attachedElements.forEach((element) => element.remove())
          publication.track.disable()
        })
        if (self.screenTrack) {
          self.room.localParticipant.unpublishTrack(self.screenTrack)
          self.screenTrack.stop()
        }
      })
      this.room.disconnect()
    },
    onDisconect () {
      let r = confirm('Bác sĩ muốn rời khỏi phòng hội chẩn?')
      if (!r) return
      this.disconnect()
      this.backToList()
    },
    backToList () {
      this.$router.push({ name: 'ListMdt' })
    },
    micChangeStatus () {
      if (this.changing_mic) return
      this.changing_mic = true
      this.micStatus ? this.muteMic() : this.unmuteMic()
      this.micStatus = !this.micStatus
    },
    camChangeStatus () {
      if (this.changing_cam) return
      this.changing_cam = true
      this.camStatus ? this.disableVideo() : this.enableVideo()
      this.camStatus = !this.camStatus
    },
    muteMic () {
      const self = this
      try {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.unpublish()
          publication.track.stop()
          if (self.mainParticipantsTrack.id === self.room.localParticipant.sid) {
            const pt = {
              id: self.room.localParticipant.sid,
              participant: self.room.localParticipant,
              tracks: self.getTrackDiffScreens(self.room.localParticipant),
              type: 'normal'
            }
            self.mainParticipantsTrack = pt
          } else {
            const lcP = self.participantsTracks.findIndex((p) => p.id === self.room.localParticipant.sid)
            if (lcP > -1) {
              self.participantsTracks[lcP] = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: self.getTrackDiffScreens(self.room.localParticipant),
                type: 'normal'
              }
            }
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.changing_mic = false
      }
    },
    unmuteMic () {
      this.createAudioTrack()
    },
    disableVideo () {
      const self = this
      try {
        this.room.localParticipant.videoTracks.forEach((publication) => {
          if (
            publication.track.name !==
            'screen' + self.room.localParticipant.sid
          ) {
            publication.unpublish()
            publication.track.stop()
            if (self.mainParticipantsTrack.id === self.room.localParticipant.sid) {
              const pt = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: self.getTrackDiffScreens(self.room.localParticipant),
                type: 'normal'
              }
              self.mainParticipantsTrack = pt
            } else {
              const lcP = self.participantsTracks.findIndex((p) => p.id === self.room.localParticipant.sid)
              if (lcP > -1) {
                self.participantsTracks[lcP] = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: self.getTrackDiffScreens(self.room.localParticipant),
                  type: 'normal'
                }
              }
            }
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.changing_cam = false
      }
    },
    enableVideo () {
      this.createVideoTrack()
    },
    createAudioTrack () {
      const self = this
      if (self.hasAudio) {
        createLocalAudioTrack().then(audio => {
          self.room.localParticipant.publishTrack(audio).then(() => {
            if (self.mainParticipantsTrack.id === self.room.localParticipant.sid) {
              const tracks = self.getTrackDiffScreens(self.room.localParticipant)
              const pt = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: tracks,
                type: 'normal'
              }
              self.mainParticipantsTrack = pt
            } else {
              const lcP = self.participantsTracks.findIndex((p) => p.id === self.room.localParticipant.sid)
              if (lcP > -1) {
                self.participantsTracks[lcP] = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: self.getTrackDiffScreens(self.room.localParticipant),
                  type: 'normal'
                }
              }
            }
          }).then(() => {
            self.changing_mic = false
          })
        })
      }
    },
    createVideoTrack () {
      const self = this
      if (self.hasVideo) {
        createLocalVideoTrack().then(video => {
          self.room.localParticipant.publishTrack(video).then(() => {
            if (self.mainParticipantsTrack.id === self.room.localParticipant.sid) {
              const tracks = self.getTrackDiffScreens(self.room.localParticipant)
              const pt = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: tracks,
                type: 'normal'
              }
              self.mainParticipantsTrack = pt
            } else {
              const lcP = self.participantsTracks.findIndex((p) => p.id === self.room.localParticipant.sid)
              if (lcP > -1) {
                self.participantsTracks[lcP] = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: self.getTrackDiffScreens(self.room.localParticipant),
                  type: 'normal'
                }
              }
            }
          }).then(() => {
            self.changing_cam = false
          })
        })
      }
    },
    getTracks (participant) {
      let self = this
      return Array.from(participant.tracks.values())
        .filter(function (publication) {
          if (self.isStreamTrack(publication.track)) return publication.track
        })
        .map(function (publication) {
          if (self.isStreamTrack(publication.track)) return publication.track
        })
    },
    getTrackDiffScreens (participant) {
      let self = this
      return Array.from(participant.tracks.values())
        .filter(function (publication) {
          if (!self.isScreen(publication.trackName) && self.isStreamTrack(publication.track)) return publication.track
        })
        .map(function (publication) {
          if (!self.isScreen(publication.trackName) && self.isStreamTrack(publication.track)) return publication.track
        })
    },

    isScreen (name) {
      return name?.includes('screen')
    },
    isStreamTrack (t) {
      return t?.kind === 'video' || t?.kind === 'audio'
    },
    async shareScreenHandler () {
      let self = this
      if (!self.screenTrack) {
        let stream = await self.getUserScreen()
        if (!stream) return
        let screenName = 'screen' + self.room?.localParticipant?.sid
        self.screenTrack = LocalVideoTrack(stream.getTracks()[0], {
          name: screenName
        })
        self.room.localParticipant.publishTrack(self.screenTrack)
        self.isShareScreen = true
        self.screenTrack.mediaStreamTrack.onended = () => {
          self.shareScreenHandler()
        }
        self.createLocalShare(self.screenTrack)
      } else {
        if (this.mainParticipantsTrack && this.mainParticipantsTrack.id === ('screen' + this.room.localParticipant.sid)) {
          this.setMeToMain()
        } else {
          let idxPt = this.participantsTracks.findIndex(pt => pt.type === 'screen' + this.room.localParticipant.sid)
          this.participantsTracks.splice(idxPt, 1)
        }
        self.room.localParticipant.unpublishTrack(self.screenTrack)
        self.screenTrack.stop()
        self.screenTrack = null
        self.isShareScreen = false
      }
    },
    zoomPTrack (p) {
      if (this.mainParticipantsTrack) {
        this.participantsTracks.push(this.mainParticipantsTrack)
      }
      let currP = this.participantsTracks.findIndex(_p => _p.type === p.type && _p.id === p.id)
      this.participantsTracks.splice(currP, 1)
      setTimeout(() => {
        this.mainParticipantsTrack = null
      }, 20)
      setTimeout(() => {
        this.mainParticipantsTrack = p
      }, 30)
    },
    trackSubscribed (track, participant) {
      if (track.name === 'screen' + participant.sid) {
        if (this.mainParticipantsTrack) this.participantsTracks.push(this.mainParticipantsTrack)
        setTimeout(() => {
          let pt = {
            id: 'screen' + participant.sid,
            participant,
            tracks: [
              track
            ],
            type: 'screen'
          }
          this.mainParticipantsTrack = pt
        }, 50)
      } else {
        let tracks = this.getTrackDiffScreens(participant)
        let p = this.participantsTracks.find(_p => _p.id === participant.sid)
        if (p) {
          p.tracks = tracks
        } else {
          let pt = {
            id: participant.sid,
            participant,
            tracks: tracks,
            type: 'normal'
          }
          this.participantsTracks.push(pt)
        }
      }
    },

    trackUnsubscribed (track, participant) {
      if (track.name === ('screen' + participant.sid)) {
        if (this.mainParticipantsTrack && this.mainParticipantsTrack.id === ('screen' + participant.sid)) {
          this.setMeToMain()
        } else {
          const p = this.participantsTracks.findIndex(_p => _p.id === ('screen' + participant.sid))
          this.participantsTracks.splice(p, 1)
        }
      } else {
        let p = null
        if (this.mainParticipantsTrack && this.mainParticipantsTrack.participant.sid === participant.sid) {
          p = this.mainParticipantsTrack
        } else {
          p = this.participantsTracks.find(_p => _p.id === participant.sid)
        }
        if (p) {
          const t = p.tracks.findIndex(_t => _t.name === track.name)
          p.tracks.splice(t, 1)
        }
      }
    },
    removeParticipant (participant) {
      if (this.mainParticipantsTrack && this.mainParticipantsTrack.participant.sid === participant.sid) {
        this.setMeToMain()
      }
      const filered = this.participantsTracks.filter(p => p.id !== participant.sid && p.id !== ('screen' + participant.sid))
      // if (this.mainParticipantsTrack && this.mainParticipantsTrack.participant.sid === participant.sid) {
      //   this.mainParticipantsTrack = null
      // }
      this.participantsTracks = filered
    },
    createLocalShare (stream) {
      if (this.mainParticipantsTrack) {
        this.participantsTracks.push(this.mainParticipantsTrack)
      }
      let pt = {
        id: 'screen' + this.room.localParticipant.sid,
        participant: this.room.localParticipant,
        tracks: [stream],
        type: 'screen'
      }
      this.mainParticipantsTrack = pt
    },
    isFirefox () {
      var mediaSourceSupport =
        !!navigator.mediaDevices.getSupportedConstraints().mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    async getDoctorInfo (id) {
      let self = this
      let exitst = null
      if (self.member_joined && self.member_joined.length) {
        exitst = self.member_joined.find((d) => d.id === id)
      }
      if (!exitst) {
        await self.$rf
          .getRequest('DoctorRequest')
          .getDoctorInfo(id)
          .then((r) => {
            self.member_joined.push(r.data)
          })
      }
    },
    async getUserScreen () {
      if (!this.canScreenShare()) {
        alert('Trình duyệt của bạn không hỗ trợ tính năng này!')
        return
      }
      if (this.isChrome()) {
        return navigator.mediaDevices
          .getDisplayMedia()
          .then((stream) => {
            return stream
          })
          .catch((e) => {
            console.log(e)
          })
      } else if (this.isFirefox()) {
        return navigator.mediaDevices.getUserMedia({
          video: {
            mediaSource: 'screen'
          }
        })
      }
    },
    refreshDisease () {
      this.$emit('refreshDisease')
    },
    hideInfo () {
      this.$emit('hideInfo')
    },
    showInfo () {
      this.$emit('showInfo')
    },
    hideDisease () {
      this.$emit('hideDisease')
    },
    showDisease () {
      this.$emit('showDisease')
    },
    showDiseaseDetail (id) {
      this.$emit('showDiseaseDetail', id)
    },
    showBienBan (show) {
      this.$emit('showBienBan', show)
    },
    showTTTQM (show) {
      this.$emit('showTTTQM', show)
    },
    isArray (p) {
      return Array.isArray(p)
    },
    getMdtDiseases (is_next, params) {
      this.$emit('getMdtDiseases', is_next, params)
    },
    startTimeCount () {
      this.total_time = this.started_seconds
      setTimeout(() => {
        this.start_count = setInterval(this.setTime, 1000)
      }, 30)
    },
    stopTimeCount () {
      clearInterval(this.start_count)
    },
    setTime () {
      this.total_time++
    },
    fancyTimeFormat (duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    closeInfoNDisease () {
      this.hideInfo()
      this.hideDisease()
    },
    setMeToMain () {
      let myTracks = null
      let idsMyTracks = -1
      this.participantsTracks.some((pt, i) => {
        if (pt.id === this.room.localParticipant.sid) {
          myTracks = pt
          idsMyTracks = i
          return true
        }
        return false
      })
      if (myTracks) {
        this.mainParticipantsTrack = myTracks
        this.participantsTracks.splice(idsMyTracks, 1)
      } else {
        this.mainParticipantsTrack = this.participantsTracks[0]
        this.participantsTracks.splice(0, 1)
      }
    },
    createParticipantsTracks (p) {
      const pt = {
        id: p.sid,
        participant: p,
        tracks: this.getTrackDiffScreens(p),
        type: 'normal'
      }
      this.participantsTracks.push(pt)
    }
  },
  beforeDestroy () {
    this.disconnect()
  }
}
</script>
<style lang="css" scoped>
.room-name {
  justify-content: flex-start;
  margin-left: 12px;
  max-width: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex: 1 1 25%;
}
.disease-in-process-name {
  max-width: calc(100vw - 542px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.bottom-action {
  align-items: center;
  display: flex;
  flex: 1 1 25%;
  justify-content: center;
}
.info-btn-group {
  align-items: center;
  display: flex;
  flex: 1 1 25%;
  justify-content: flex-end;
  margin-right: 18px;
}
.b-action {
  width: 15vw;
  max-width: 86px;
  height: 40px;
  border-radius: 8px;
  border: 0;
  margin-right: 1.25vw;
  margin-bottom: 3px;
}
.b-action svg {
  max-width: 100%;
}
.action-active {
  background-color: #7f7f7f;
}
.action-inactive {
  background-color: #3c4043;
}
.b-leave {
  background-color: #ff3636;
}
.call-container {
  position: relative;
}
.box-shadow {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
button:disabled {
  cursor: not-allowed;
}
.btn-open-tab {
  width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  margin-right: 5px;
}
.btn-header {
  width: 142px;
  max-width: 30vw;
}
.footer-wraper {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  color: #202124;
  display: flex;
  margin-bottom: 0;
  position: fixed;
  text-align: center;
  white-space: nowrap;
  width: 100vw;
  z-index: 100;
}
.bg-3C4043 {
  background-color: #3c4043;
}
.empty-wraper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-open-info {
  width: 40px;
  height: 40px;
  background-color: #3c4043;
  border: 0;
}
</style>