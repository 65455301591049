<template>
    <div class="telemedicine-management pr-3">
        <div class="row">
            <div class="col-12">
                <div class="d-flex py-1 align-items-center">
                    <img src="../../../public/assets/images/icon/big-blue-video.svg" alt="" class="mr-2 page-icon"><span class="txt-pri page-title">Telemedicine</span>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row justify-content-lg-end">
                    <div class="col-auto mr-auto">
                        <ul class="nav nav-tabs type-bar mb-1">
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" @click="changeType('upcoming')" :class="type=='upcoming' ? 'active' : ''">Lịch hẹn</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" @click="changeType('history')" :class="type=='history' ? 'active' : ''">Báo cáo</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-auto mb-1">
                        <div class="row">
                            <div class="col-auto">
                                <SearchInput @onSearch="onSearch" :search="search"></SearchInput>
                            </div>
                            <div class="col-auto pb-1">
                                <button v-if="can('create-new-appt')" class="btn-l btn--blue" type="button" @click="onAddNewAppt()"><span class="fa fa-plus mr-2"></span> Thêm lịch hẹn</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
            <!-- {{appointments.data}} -->
            <div class="table-responsive">
                <table class="table table-vcenter table-mobile-md card-table table-scroll">
                    <thead>
                        <tr>
                            <th>Thông tin bệnh nhân</th>
                            <!-- <th>Bệnh lý</th> -->
                            <th>Lịch khám</th>
                            <th>Bác sĩ</th>
                            <th v-if="type == 'upcoming'">Mã hóa đơn</th>
                            <th v-if="type == 'upcoming'">Trạng thái</th>
                            <th v-if="type == 'history'">Thời gian gọi</th>
                            <th v-if="type == 'history'">Đánh giá</th>
                            <th class="text-center">Hoạt động</th>
                        </tr>
                    </thead>
                    <tbody v-show="appointments && appointments.count" id="appt_list" :class="appointments && appointments.count >9 ? 'h-100' : ''">
                        <tr v-for="appointment in appointments.data" :key="appointment.id">
                            <td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
                                <div class="d-flex py-1 align-items-center">
                                    <div style="min-width: 40px;">
                                        <span v-if="hasAvatar(appointment)" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(appointment.person.user.avatar)+')'"></span>
                                        <span v-if="!hasAvatar(appointment)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
                                            {{ appointment.person && appointment.person.name ? appointment.person.name.charAt(0) : '' }}
                                        </span>
                                    </div>
                                    <div class="flex-fill">
                                        <p class="p-0 m-0">{{appointment.person ? appointment.person.name : ''}}</p>
                                        <span class="robo-12-400 text-muted">{{appointment.person ? formatPhoneNumber(appointment.person.phone) : ''}}</span>
                                    </div>
                                </div>
                            </td>
                            <!-- <td data-label="Bệnh lý">
                                {{appointment.case ? appointment.case.name : ''}}
                            </td> -->
                            <td data-label="Lịch khám" style="text-transform: capitalize;" v-html="appointment.start_time ? formatWeekdayTimev2(appointment.start_time) : ''"></td>
                            <td data-label="Bác sĩ">
                                <p class="m-0 p-0">
                                    {{appointment.doctor ? appointment.doctor.name : ''}}
                                </p>
                                <span class="robo-12-400 text-muted">Lịch hẹn được tạo lúc {{appointment.start_time ? formatWeekdayTime(appointment.created_at) : ''}}</span>
                            </td>
                            <td v-if="type == 'upcoming'" data-label="Mã hóa đơn">
                                {{appointment.invoice ? appointment.invoice.code : ''}}
                            </td>
                            <td v-if="type == 'upcoming'" data-label="Trạng thái">
                                <span v-if="appointment.status == 2 || appointment.status == 5" :class="appointment.invoice ? getInvoiceStatus(appointment.invoice.status).class : ''">{{appointment.invoice ? getInvoiceStatus(appointment.invoice.status).text : ''}}</span>
                                <span v-if="appointment.status != 2 && appointment.status != 5" :class="getApptStatus(appointment.status).class">{{getApptStatus(appointment.status).text}}</span>
                            </td>
                            <td v-if="type == 'history'" data-label="Thời gian gọi">
                                {{getCallDuration(appointment)}}
                            </td>
                            <td v-if="type == 'history'" data-label="Đánh giá">
                                <ul class="rating-star">
                                    <li v-for="star in getRatingStar(appointment)" :key="star + 'star'">
                                      <i class="fa fa-star" :class="star.active ? 'text-yellow' : 'text-secondary'" aria-hidden="true"></i>
                                    </li>
                                </ul>
                            </td>
                            <td data-label="Hành động" class="table-td-action">
                                <div class="d-flex justify-content-center flex-wrap">
                                    <img v-if="permitCall(appointment) && checkMyAppt(appointment)" src="../../../public/assets/images/icon/blue-video.svg" alt="" class="cursor-pointer" @click="onShowCall(appointment)">
                                    <img v-if="!permitCall(appointment) || !checkMyAppt(appointment)" src="../../../public/assets/images/icon/gray-video.svg" alt="">
                                    <!-- <img @click="onShowNote(appointment)" src="../../../public/assets/images/icon/note.svg" alt="" class="cursor-pointer"> -->
                                    <a v-if="type == 'upcoming'" @click="changeToUrlInvoice(appointment.invoice.id,appointment.invoice.token)">
                                        <img v-if="appointment.invoice && type == 'upcoming'" src="../../../public/assets/images/icon/invoice.svg" alt="" class="cursor-pointer">
                                    </a>
                                    <!-- <router-link to="/foo">Go to Foo</router-link> -->
                                    <img v-if="!appointment.invoice && type == 'upcoming'" src="../../../public/assets/images/icon/invoice-de.svg" alt="">
                                    <div v-if="type == 'upcoming'" class="dropdown show">
                                        <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="../../../public/assets/images/icon/3dot.svg" alt="" class="cursor-pointer">
                                        </a>
                                        <div class="dropdown-menu radius-18 border-0 shadow" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="javascript:;" @click="onEditAppt(appointment)">Chỉnh sửa</a>
                                            <a class="dropdown-item" href="javascript:;" @click="onDeleteAppt(appointment.id)">Xóa</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="loading">
                        <tr v-for="i in 5" :key="i+ 'sk'">
                            <td>
                                <div class="skeleton-avatar avatar-sm avatar-rounded">
                                </div>
                            </td>
                            <td>
                                <div class="skeleton-line"></div>
                            </td>
                            <td>
                                <div class="skeleton-line"></div>
                            </td>
                            <td>
                                <div class="skeleton-line"></div>
                            </td>
                            <td>
                                <div class="skeleton-line"></div>
                            </td>
                            <td>
                                <div class="skeleton-line"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination v-if="appointments && appointments.count"  :items="appointments" :current_page="current_page" @onRefresh="onRefresh"></Pagination>
            </div>
            <div v-if="appointments && !appointments.count && !loading" class="row">
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                    <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
                    <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy lịch hẹn mà bạn tìm' : 'Chưa có lịch hẹn nào!' }}</p>
                    <button v-if="can('create-new-appt')" class="btn-xl btn--blue" type="button" @click="onAddNewAppt()"><span class="fa fa-plus mr-2"></span> Thêm lịch hẹn</button>
                </div>
            </div>
        </div>
        <ModalDoctorNoteAdd :form="form" @onSubmitNote="updateApptNote"></ModalDoctorNoteAdd>
        <ModalAppointment @onRefresh="onRefresh" :root_type="2" :appt_edit="appt_edit" :root_screen="'Video Call Management'" :screen="'Video Call Management'"></ModalAppointment>
        <ModalLinkRoom :link_room="link_room" :appt_id="appt_id"></ModalLinkRoom>
    </div>
</template>
<script>
// import _ from 'lodash'
import appUtils from '../../utils/appUtils'
import ModalDoctorNoteAdd from '../../components/ModalDoctorNoteAdd'
import ModalAppointment from '../../components/Appointment/ModalAppointment'
import ModalLinkRoom from '../../components/VideoCall/ModalLinkRoom'
import SearchInput from '../../components/SearchInput'
import Pagination from '../../components/Pagination'
export default {
  mixins: [],
  name: 'VideoCallManagement',
  components: { ModalDoctorNoteAdd, ModalAppointment, ModalLinkRoom, SearchInput, Pagination },
  computed: {
    thisDoctorID () {
      if (appUtils.isDoctor()) {
        return appUtils.getLocalUser().doctor.id
      }
    }
  },
  data () {
    return {
      search: '',
      appointments: {},
      type: this.$route.query.type ? this.$route.query.type : '',
      form: { doctor_note: null },
      selected_appt: {},
      appt_edit: null,
      link_room: '',
      appt_id: '',
      loading: false,
      user: {},
      clinic: {},
      current_page: 1
    }
  },
  mounted () {
    let self = this
    if (!self.type || (self.type !== 'upcoming' && self.type !== 'history')) {
      self.type = 'upcoming'
      self.$router.push({ query: { type: 'upcoming' } }).catch(() => {})
    }
    self.getAppointments()
    // let appt_list = document.querySelector('#appt_list')
    // appt_list.addEventListener('scroll', e => {
    //   if (Math.ceil(appt_list.scrollTop + appt_list.clientHeight) >= appt_list.scrollHeight) {
    //     if (self.appointments.count < self.appointments.total) {
    //       self.getAppointments(self.appointments.count + 10)
    //     }
    //   }
    // })
    window.$(document).ready(function () {
      window.$('#modal__appt').on('hidden.bs.modal', function (e) {
        self.appt_edit = null
      })
    })
    self.user = appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    self.sendAmplitude('View Telemed List', {
      'doctor id': self.user && self.user.id,
      'doctor name': self.user && self.user.name,
      'workspace id': self.clinic && self.clinic.id,
      'workspace name': self.clinic && self.clinic.name,
      'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
      'request id': ''
    })
  },
  methods: {
    checkMyAppt (appt) {
      let self = this
      var doctor_id = self.user && self.user.doctor && self.user.doctor.id
      if (appt) {
        return appt.doctor_id === doctor_id
      }
    },
    onSearch (kw) {
      this.search = kw
      this.getAppointments()
    },
    permitCall (appt) {
      let self = this
      if (appt && appt.status !== 1 && appt.status !== 3 && appt.status !== 4 && appt.status !== 6) {
        // if (self.type === 'upcoming') {
        //   if (appt.room_call) {
        //     if (self.isPaid(appt)) {
        //       return true
        //     } else {
        //       return false
        //     }
        //   } else {
        //     return false
        //   }
        // } else {
        //   return false
        // }
        if (appt.room_call) {
          if (self.isPaid(appt)) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    onShowCall (appt) {
      if (appt && appt.invoice && appt.invoice.status === 3 && appt.room_call) {
        this.link_room = this.renderLink(appt.room_call)
        this.appt_id = appt.id
        this.onShowModalLink(true)
        // if (appt.room_call.status !== 2) {
        //   this.link_room = this.renderLink(appt.room_call)
        //   this.appt_id = appt.room_call.id
        //   this.onShowModalLink(true)
        // } else {
        //   this.$toast.open({
        //     message: 'Phòng đã đã kết thúc hoặc quá hạn!',
        //     type: 'error'
        //   })
        // }
      }
    },
    renderLink (room) {
      if (room) {
        this.slt_room = room
        return process.env.VUE_APP_BASE_URL + 'redirect.html?id=' + room.id + '&token=' + room.token
      }
    },
    onRefresh () {
      this.onShowModalAppt(false)
      this.getAppointments()
    },
    changeType (type) {
      this.type = type
      this.appointments = {}
      self.$router.replace({ query: { type: type, page: 1 } })
      setTimeout(() => {
        this.getAppointments()
      }, 100)
    },
    isPaid (appt) {
      // const PATIENT_PENDING = 1
      // const PATIENT_PAID = 2
      // const DOCTOR_ACCEPT = 3
      if (appt) {
        if (appt.invoice) {
          return appt.invoice.status === 3
        } else {
          return false
        }
      }
    },
    getInvoiceStatus (stt) {
      let statuses = []
      statuses[1] = {text: 'Chưa thanh toán', class: 'text--red'}
      statuses[2] = {text: 'Chờ xác nhận', class: 'text--yellow'}
      statuses[3] = {text: 'Đã thanh toán', class: 'txt-pri'}
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    getApptStatus (stt) {
      let statuses = []
      statuses[1] = {class: 'text--yellow', text: 'Chờ xác nhận'}
      statuses[2] = {class: 'txt-pri', text: 'Xác nhận khám'}
      statuses[3] = {class: 'text--red', text: 'Bác sĩ từ chối'}
      statuses[4] = {class: 'text-yellow-corl', text: 'Chờ xác nhân'}
      statuses[5] = {class: 'txt-pri', text: 'Xác nhận khám'}
      statuses[6] = {class: 'text--red', text: 'Bệnh nhân từ chối'}
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    onShowNote (appt) {
      this.form.doctor_note = appt.note
      this.selected_appt = appt
      this.onShowModalNote(true)
    },
    async getAppointments (limit) {
      let self = this
      self.loading = true
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        limit: limit || 10,
        sort: 'start_time',
        sort_by: 'asc',
        clinic_id: this.$clinicId ? this.$clinicId : '',
        method: 2,
        page: self.current_page
      }
      if (self.search) {
        params.search = self.search
      }
      if (self.type === 'upcoming') {
        params.statuses = [1, 2, 4, 5]
        // let start_time = new Date()
        // params.start_time = self.formatTimeDate(start_time)
        // params.start_time = self.formatTimeDateAfterSubtractOneHourse(start_time)
        params.start_time = window.moment(new Date()).startOf('day').format('HH:ss DD-MM-YYYY')
        params.end_time = window.moment(new Date()).endOf('day').format('HH:ss DD-MM-YYYY')
      }
      if (self.type === 'history') {
        // let end_time = new Date()
        // params.end_time = self.formatTimeDate(end_time)
        // params.end_time = self.formatTimeDateAfterAddOneHourse(end_time)
        params.end_time = self.formatTimeDate(window.moment().startOf('today'))
      }
      try {
        let resp = await self.$rf.getRequest('DoctorRequest').getAppointments(params)
        self.appointments = resp.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async updateApptNote (data) {
      let self = this
      self.loading = true
      let params = {
        id: null,
        start_time: self.formatTimeDateSlash(self.selected_appt.start_time),
        end_time: self.formatTimeDateSlash(self.selected_appt.end_time),
        note: data.doctor_note,
        person_id: self.selected_appt.person_id,
        doctor_id: self.selected_appt.doctor_id,
        method: self.selected_appt.method,
        price: self.selected_appt.price
      }
      try {
        await self.$rf.getRequest('DoctorRequest').updateAppointment(self.selected_appt.id, params)
        // self.sendAmplitude('Update Appointment', {
        //   'Status': 'Success',
        //   'Type': 'Modal',
        //   'Modal Name': 'Update Appointment',
        //   'Screen': self.screen,
        //   'Root Screen': self.root_screen,
        //   'Timestamp': self.formatTimeDate(new Date()),
        //   'Error': 'none'
        // })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onAddNewAppt (appt) {
      this.appt_edit = null
      this.onShowModalAppt(true)
      let user = appUtils.getLocalUser()
      let clinic = this.$globalClinic
      this.sendAmplitude('Add Telemed', {
        'doctor id': user.id,
        'doctor name': user.name,
        'workspace id': clinic.id,
        'workspace name': clinic.name,
        'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
        'request id': ''
      })
    },
    onEditAppt (appt) {
      this.appt_edit = appt
      this.onShowModalAppt(true)
    },
    async onDeleteAppt (id) {
      let self = this
      let cf = window.confirm('Bạn có chắc chắn muốn xóa lịch hẹn này?')
      if (cf) {
        try {
          await self.$rf.getRequest('DoctorRequest').deleteAppointment(id)
          self.getAppointments()
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    },
    getCallDuration (appt) {
      if (appt) {
        if (appt.room_call) {
          if (appt.room_call.start_time && appt.room_call.end_time) {
            return this.millisToMinutesAndSeconds(new Date(appt.room_call.end_time) - new Date(appt.room_call.start_time))
          } else {
            return '00:00'
          }
        } else {
          return '00:00'
        }
      }
    },
    getRatingStar (appt) {
      let star = {

      }
      star[5] = [
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true }
      ]
      star[4] = [
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: false }
      ]
      star[3] = [
        { active: true },
        { active: true },
        { active: true },
        { active: false },
        { active: false }
      ]
      star[2] = [
        { active: true },
        { active: true },
        { active: false },
        { active: false },
        { active: false }
      ]
      star[1] = [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: false }
      ]
      star[0] = [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false }
      ]
      if (appt) {
        if (appt.room_call) {
          if (appt.room_call.pr_service) {
            return star[appt.room_call.pr_service]
          } else {
            return star[0]
          }
        } else {
          return star[0]
        }
      }
    },
    millisToMinutesAndSeconds (millis) {
      let minutes = Math.floor(millis / 60000)
      let seconds = ((millis % 60000) / 1000).toFixed(0)
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    },
    hasAvatar (appt) {
      return !!(appt && appt.person && appt.person.user && appt.person.user.avatar)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatWeekdayTime (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow()
      } else {
        return window.moment(date_time).format('HH:mm DD/MM/YYYY')
      }
    },
    formatWeekdayTimev2 (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow() + '<br><small class="robo-12-400 text-muted">' + this.formatTimeDate(date_time) + '</small>'
      } else {
        // return window.moment(date_time).format('HH:mm DD/MM/YYYY')
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    },
    formatPhoneNumber (number) {
      // Filter only numbers from the input
      let cleaned = ('' + number).replace(/\D/g, '')

      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

      if (match) {
        // let hiden_number = ''
        // for (var i = 0; i < match[2].length; i++) {
        //   hiden_number += 'X'
        // }
        return match[1] + '-' + match[2] + '-' + match[3]
      };

      return null
    },
    formatTimeDateAfterSubtractOneHourse (date_time) {
      return window.moment(date_time).subtract(12, 'Hours').format('HH:mm DD-MM-YYYY')
    },
    formatTimeDateAfterAddOneHourse (date_time) {
      let clinic = this.$globalClinic
      console.log(clinic)
      return window.moment(date_time).add(12, 'Hours').format('HH:mm DD-MM-YYYY')
    },
    formatTimeDate (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    formatTimeDateSlash (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    onShowModalNote (show) {
      window.$('#modal__note').modal(show ? 'show' : 'hide')
    },
    onShowModalAppt (show) {
      window.$('#modal__appt').modal(show ? 'show' : 'hide')
    },
    onShowModalLink (show) {
      window.$('#modal__link').modal(show ? 'show' : 'hide')
    },
    changeToUrlInvoice (id, token) {
      let self = this
      // alert(id + ' ' + token)
      self.$router.push({ path: '/doctor/invoice/' + id + '?token=' + token })
    }
  }
}
</script>
<style lang="css">
.rating-star li {
    display: inline-flex;
    margin-right: 3px;
}

.rating-star {
    display: flex;
    padding: 0;
    margin: 0;
}

.search-box-table {
    height: 50px;
}
</style>